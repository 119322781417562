<template>
    <div v-if="drag" class="content-drop-zone" :class="{dragover: dragOver, active: drag}"
        @drop.stop="drop($event)"
        @dragover.prevent="dragOver=true"
        @dragenter.prevent
        @dragleave.prevent="dragOver=false"
    > </div>
  
</template>

<script>
import useContentDrag from '@admin/composable/ContentDrag';
import { ref } from 'vue';
export default {
    name: "ContentDropZone",
    props: {
        placement_key: String,
        index: Number
    },
    setup(props, context) {
        const { onDrop,drag } = useContentDrag();
        const dragOver = ref(false);

        const drop = (event) => {
            onDrop(event,props.placement_key, props.index,context);
            dragOver.value=false;
        }

        return {
            dragOver,
            drop,
            drag
        }
    }
}
</script>

<style scoped>
.content-drop-zone{
    min-height: 3rem;
    background: green;
    z-index: -100;
    opacity: 0;
    margin: -1.5rem 0;
}

.content-drop-zone.active{
    z-index: 10;
    opacity: 0.3;
}
</style>