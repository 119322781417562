<template>
    <div class="page-placement">
        <div class="layout-buttons">
            <BaseButton class="layout-button btn-success" type="button" @click="openModal('add_content')" :title="$t('cms.add_new_content_block')">
                <BaseIcon class="plus_black" />
            </BaseButton>
            <BaseButton class="layout-button btn-add" type="button" @click="openModal('insert_content')" :title="$t('cms.insert_content_block')">
                <BaseIcon class="add_block" />
            </BaseButton>
        </div>
        <transition-group name="list" tag="div">
            <div
                v-for="(content,index) in contents" 
                :key="content.content.code"
            >
                <ContentDropZone :placement_key="placement_key" :index="content.position" @setPosition="setPosition({data: $event})" />
                <ContentEditor 
                    :content="content.content" 
                    :placement_key="placement_key"
                    :index="content.position"
                    :status="content.status"
                    @updateValues="updateContent({index,data: $event})" 
                >
                </ContentEditor>
            </div>
        </transition-group>
        
        <ContentDropZone class="last-drop" :placement_key="placement_key" :index="-1" @setPosition="setPosition({data: $event})" />
        
        <teleport to="#app">
            <ContentTypesSelection :content="{}"  :opened="openedModal === 'add_content'" @close="closeModal()" @updateValues="addContent({data: $event})" />
            <ContentSelection :content="{}"  :opened="openedModal === 'insert_content'" @close="closeModal()" @pickItem="addContent({data: $event})" />
        </teleport>
    </div>
</template>

<script>
import {  computed, defineAsyncComponent, ref } from 'vue';
import ContentEditor from "./ContentEditor";
import { useI18n } from 'vue-i18n';
import ContentDropZone from './ContentDropZone';
import { useStore } from 'vuex';

export default {
    name: "PagePlacementAdmin",
    components: {
        ContentEditor,
        ContentDropZone,
        ContentTypesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "content-type-selection" */'@admin/components/cms/content/ContentTypeSelection')),
        ContentSelection: defineAsyncComponent(() => import(/* webpackChunkName: "content-selection" */'@admin/components/cms/content/ContentSelection')),
    },
    props: {
        placement_key: {
            type: String,
            required: true
        }
    },
    setup(props){
        const i18n = useI18n();
        
        const store = useStore();
        
        // modal window
        const openedModal = ref(null);
        const openModal = (modal) => {
            openedModal.value = modal;
        }
        const closeModal = () => {
            openedModal.value = null;
        }
        const contents = computed(() => store.getters['page_editor/getContents'](props.placement_key));

        const updateContent = ({index,data}) => {
            let updated = contents.value;
            
            updated[index]['content'] = Object.assign(updated[index]['content'],data);
            // updated[index]['content']['data'] = data;
            //console.log(updated[index]);
            // context.emit('updateContent',updated[index]);
            store.commit('page_editor/updateContent',{data:updated[index]});
        }
        const addContent = ({data}) => {
            data.name = i18n.t('cms.content_block');
            store.commit('page_editor/addContent',{data,placementCode: props.placement_key});
        }
        const setPosition = ({data}) => {
            store.commit('page_editor/setPosition',{data});
        }

        return {
            closeModal,
            openModal,
            openedModal,
            updateContent,
            addContent,
            setPosition,
            contents
        }

    }
}
</script>

<style scoped>
.page-placement{
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 2px dotted lightgray;
    margin: 0.1rem;
    min-height: 8rem;
}

.layout-button{
    border-radius: 50%;
    margin-right: 0.3rem;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
}
.layout-button .icon{
    width: 1rem;
    height: 1rem;
    margin: 0
}
.layout-buttons{
    background: #e9fff3;
    border-bottom: 1px solid #cde0d5;
    padding: 0.2rem 0.4rem;
}
.btn-add{
    background: #6ac19d;
}
.last-drop{
    flex-grow: 1;
}
.list-move {
  transition: transform 0.8s ease;
}
</style>
